import React from 'react';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import CircularProgress from '@material-ui/core/CircularProgress';


function CircularLoader(props) {
    return (
        <GridList style={{ display: 'flex', justifyContent: 'center' }}>
            <Grid container direction="column" justify="center" alignItems="center" style={{ minHeight: '85vh' }}>
                <CircularProgress />
            </Grid>
        </GridList>
    );
}

export default CircularLoader;