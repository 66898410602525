import React from 'react';
import Rainbow from '../hoc/Rainbow'
const About = () => {
    return (
        <div className="container text-center">
            <h5>About</h5>
        </div>
    )

}

export default Rainbow(About)