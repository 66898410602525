import axios from "axios";
import React, { Component } from 'react';
import LazyHero from 'react-lazy-hero';
import "../../resource/css/custom.css";
import Loader from './Loader';
import Helper from "../../helpers/General";
import { withRouter, Link } from "react-router-dom";
import PlayArrow from '@material-ui/icons/PlayArrow';
import { Icon } from "react-icons-kit";
import Chip from "@material-ui/core/Chip";
import { ic_navigate_before } from 'react-icons-kit/md/ic_navigate_before'

class Landing extends Component {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            isLoaded: false,
            count: 0,
            error: false
        };
        
    }

    state = this.state;
    
    componentDidMount() {

        var params = {
          api_key: "4d9c9de3bdf0d3b6837c49c086e3b190"
        };

        var url = "https://api.themoviedb.org/3/trending/movie/week?" + Helper.getAsUriParameters(params);
        axios.get(url)
        .then(res => {
                var random = Math.floor((Math.random() * 20));
                var trending_movie = res.data.results.slice(0, 20)[random];
                var url_ = "https://api.themoviedb.org/3/movie/"+trending_movie.id+"?" + Helper.getAsUriParameters(params);
                axios.get(url_)
                    .then(res => {
                        var movieDetails = res.data;
                        this.setState({
                            items: movieDetails,
                            isLoaded: true
                        });
                    })
            })
    }
    render() {
        
        const { error, isLoaded, items } = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <Loader />;
        } else {
            var trendingMovie = items;
            var thisGenres = '';
            thisGenres = trendingMovie.genres.map((genre) => {
                return (
                    <span key={Math.random()} className="genreItem">{genre.name}</span>
                )
            })
            return (
                <div>
                    <LazyHero
                        imageSrc={
                            "https://image.tmdb.org/t/p/original/" + trendingMovie.backdrop_path
                        }
                        isFixed={true}
                        isCentered={true}
                        transitionTimingFunction="ease-in-out"
                        transitionDuration={900}
                        parallaxOffset={150}
                        className="landinghero"
                        minHeight="70vh"
                    >
                        <div className="container mainFilm">
                            <h1 className="heading">
                                {trendingMovie.title}
                                <small>{trendingMovie.tagline}</small>
                            </h1>

                            <Chip label={trendingMovie.release_date.split("-")[0]} color="primary" />

                            <div className="row padding-reset-x-0">
                                <h4 className="subheader">{trendingMovie.overview}</h4>
                                <div className="genreHolder">
                                    {thisGenres}
                                </div>
                            </div>
                        </div>
                    </LazyHero>
                </div>
            );
        }
    }
}


export default withRouter(Landing);
