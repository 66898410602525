import React from 'react';
import { Icon } from "react-icons-kit";
import { cancelCircle } from 'react-icons-kit/icomoon/cancelCircle'

import {  Link } from "react-router-dom";
export default class IframeComponent extends React.Component {
    render() {
        if(this.props.src !== undefined){

            return (
            <div>
                <div id="closeIframe">
                <Link  to={`/item/${this.props.id}`} target="_self">
                        <Icon icon={cancelCircle} size={20} />
                </Link>
                </div>
                <iframe
                title={this.props.src}
                allowFullScreen={true}
                allowtransparency="true"
                scrolling="no"
                frameBorder="0"
                src={this.props.src}
                height={this.props.height}
                width={this.props.width}
                className="fullheight"
                id="iFrameFullScreen"
                />
            </div>
            );
        }else{
            return (<div></div>)
        }
    }
}
