import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { NavLink, withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import $ from 'jquery';
import axios from 'axios'
import Helper from "../../helpers/General"

import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import { Icon } from "react-icons-kit";
import { ic_arrow_back } from 'react-icons-kit/md/ic_arrow_back'
import { ic_arrow_forward } from 'react-icons-kit/md/ic_arrow_forward'
import { ic_remove } from 'react-icons-kit/md/ic_remove'
import { ic_star } from 'react-icons-kit/md/ic_star'
import { happy } from 'react-icons-kit/icomoon/happy'
import { film } from 'react-icons-kit/icomoon/film'
import StarRatingComponent from './StarRatingComponent';
window.React = React;

export class CommentList extends Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
    };

    render() {
        let movieNodes = this.props.data.map(function (movie, index) {
            return (
                <Grid key={movie.id} item xs={6} sm={4} md={3}>
                    <NavLink to={`/item/${movie.id}`} key={index}>

                        {/* <div className="movieOverlay">&nbsp;</div> */}
                        <div className="movieCard" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, .5) 20%, rgba(0, 0, 0, 0.75) 90%, rgb(0, 0, 0) 100%), url("` + `https://image.tmdb.org/t/p/w500/` + movie.backdrop_path + `")` }}>
                            <div className="movieDescription">
                                <small className="primarytext">{movie.release_date.split("-")[0]}</small>
                                <h4 className="movieTitle">{movie.title}</h4>
                                <div>
                                    <StarRatingComponent
                                        name="rate2"
                                        editing={false}
                                        renderStarIcon={() => <span><Icon icon={happy} size={15} /></span>}
                                        starCount={5}
                                        value={Math.floor(movie.vote_average / 2)}
                                    />
                                </div>
                                <p>{movie.overview}</p>

                            </div>
                        </div>
                    </NavLink>
                </Grid>
            );
        });

        return (<Grid container className="maxWidth" spacing={12}>{movieNodes}</Grid>);
    }
}

export class App extends Component {

    constructor(props) {
        super(props);
        this.searchResults = React.createRef()
        this.state = {
            data_search: [],
            offset_search: 0,
            search: '',
            pageCount_search: 0
        };
        this._isMounted = false;
    }

    async loadCommentsFromServer() {
        var params = {
            api_key: "4d9c9de3bdf0d3b6837c49c086e3b190",
            query: (this.state.search !== null && this.state.search !== '') ? this.state.search : ' ',
            page: (this.state.page_search !== null) ? Math.ceil(this.state.page_search + 1) : 1
        };
        
        if(params.query == null){
            this._isMounted && this.setState({
                data_search: [],
                pageCount_search: 0,
            });
        } else {
            var url = "https://api.themoviedb.org/3/search/movie?" + Helper.getAsUriParameters(params);
            axios.get(url)
                .then(data => {
                    var data_ = data.data;
                    this._isMounted && this.setState({
                        data_search: data_.results,
                        pageCount_search: data_.total_pages,
                    });
                })
        }
    }
    changeText = (event) => {
        if(event.target.value == ''){
            this.setState({ search: null, page_search: null }, () => {
                this.loadCommentsFromServer();
            });
        }else{
            this.setState({ search: event.target.value, page_search: null }, () => {
                this.loadCommentsFromServer();
            });

        }

    }

    handleScrollToElement = (event) =>  {
        window.scrollTo(0, Math.ceil(document.getElementById('searchResultsSection').offsetTop - 100));
    }

    handleSearchSubmit = (data) => {
        let search = this.state.search;
        // window.scrollTo(0, this.searchResults.current.offsetTop)
        this.setState({ search: search, page_search:null}, () => {
            this.loadCommentsFromServer();
        });
    }
    componentDidMount() {
        this._isMounted = true;
        this.handleScrollToElement()
        this.loadCommentsFromServer();

    }
    componentWillUnmount() {
        this._isMounted = false;

    }
    handlePageClick = data => {
        let selected = data.selected;
        let offset = Math.ceil(selected * 20);
        this.setState({page_search : 1});
        this.setState({ offset_search: offset, page_search: selected }, () => {
            this.loadCommentsFromServer();
        });
        this.handleScrollToElement();
    };

    render() {
        var paginate_inc = '';
        if (this.state.data_search.length > 0){
            paginate_inc = <ReactPaginate
                previousLabel={<Icon icon={ic_arrow_back} size={32} />}
                nextLabel={<Icon icon={ic_arrow_forward} size={32} />}
                breakLabel={<Icon icon={ic_remove} size={32} />}
                breakClassName={'break-me'}
                pageCount={this.state.pageCount_search}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={this.handlePageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
            />
        }
        if (this._isMounted) {
            return (
                <div id="searchResultsSection">
                    <h2 className="maxWidth sectionHeader" >
                        <Icon icon={film}size={32}className="primarytext"/>{" "}
                        Search a Movie
                    </h2>
                    
                    <form id="searchMovieInput" onSubmit={e => { e.preventDefault(); this.handleSearchSubmit(); }}>
                        <input type="text" name="search" placeholder="..." value={this.state.search} onChange={this.changeText} />
                    </form>

                    <CommentList  data={this.state.data_search} />

                    {paginate_inc}
                </div>
            );
        } else {
            return (
                <div id="searchResultsSection">
                    <h2 className="maxWidth sectionHeader" ref={this.searchResults}>
                        <Icon icon={film} size={32} className="primarytext" />{" "}
                        Search a Movie
                    </h2>

                <form id="searchMovieInput" onSubmit={e => { e.preventDefault(); this.handleSearchSubmit(); }}>
                    <input type="text" name="search" placeholder="..." value={this.state.search} onChange={this.changeText} />
                </form>

                    {paginate_inc}
                </div>
                )
        }

    }
}


ReactDOM.render(<App url={'https://api.themoviedb.org/3/trending/movie/day?api_key=4d9c9de3bdf0d3b6837c49c086e3b190'} author={'adele'} perPage={10} />, document.getElementById('root'));

export default withRouter(App);
