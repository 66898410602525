import React, { Component } from 'react';
import LazyHero from 'react-lazy-hero';
import '../resource/css/custom.css';
import Loader from './inc/Loader';
import Iframe from "./inc/Iframe";
import Recommended from "./inc/Recommended";
import Helper from "../helpers/General";
import { withRouter, Link } from "react-router-dom";
import PlayArrow from '@material-ui/icons/PlayArrow';
import Button from "@material-ui/core/Button";
import anime from 'animejs';
import { Icon } from "react-icons-kit";
import Chip from "@material-ui/core/Chip";
import {ic_navigate_before} from 'react-icons-kit/md/ic_navigate_before'
import { ic_camera_front } from 'react-icons-kit/md/ic_camera_front'
import { ic_slideshow } from 'react-icons-kit/md/ic_slideshow'
import Grid from "@material-ui/core/Grid";
// import Anime from 'react-anime';
import YoutubeBackground from "react-youtube-background";
import YouTube from "react-youtube";

import reloadable from '../hoc/Reloadable'
import { happy } from 'react-icons-kit/icomoon/happy'
// import { film } from 'react-icons-kit/icomoon/film'
import StarRatingComponent from './inc/StarRatingComponent';

import ScrollMenu from 'react-horizontal-scrolling-menu';
import { UserAgent } from "react-useragent";
// import FeaturedPlay from '@material-ui/icons/FeaturedPlayList';
import axios from "axios";
class Movie extends Component {
  constructor(props) {
    super(props);

    this.state = {
      movieID: props.match.params.id,
      item: [],
      streams: [],
      iframeBody: '',
      isLoaded: false,
      count: 0,
      error: false,
      orientation: 'portrait'
    };
    if (window.innerWidth < window.innerHeight) {
      this.setState({
        orientation: 'landscape'
      })
    }
    // this.handleChange();

      this.playThisMovie = this.playThisMovie.bind(this);
  }

//   playThisMovie() {
//     var url = this.state.streamUrl;
    
//     this.setState(state => ({
//       isToggleOn: !state.isToggleOn
//     }));
//   }

fadeInBackgroundVid = ()=>{
      anime({
            targets: '.Player__videoContainer___2TVqS',
            // translateY: [0, -200],
            opacity: [0, 1],
            delay: 5000,
            easing: 'easeInOutSine',
            duration:300,
            loop: false
        });
}

fadeInDetails = () =>{

}
  
handleChange = () => {
  var self = this;          // Store `this` component outside the callback
  
  if (window.innerWidth < window.innerHeight) {
    this.setState({
      orientation: 'landscape'
    })
  }

  if ('onorientationchange' in window) {


    window.addEventListener("orientationchange", function () {
      // `this` is now pointing to `window`, not the component. So use `self`.
      // self.setState({
      //   orientation: !self.state.orientation
      // })
      if (window.innerWidth < window.innerHeight) {
        self.setState({
          orientation: 'landscape'
        })
      } else {
        self.setState({
          orientation: 'portrait'
        })
      }
      
      if (self.state.orientation === 'portrait') {
        document.getElementById("root").classList.remove("landscape");
        document.getElementById("root").classList.add("portrait");
      } else {
        document.getElementById("root").classList.remove("portrait");
        document.getElementById("root").classList.add("landscape");
      }
    }, false);
    this.setState({
      orientation: self.state.orientation
    })
  }
  if(this.state.orientation === 'portrait'){
    document.getElementById("root").classList.remove("landscape");
    document.getElementById("root").classList.add("portrait");
  } else{
    document.getElementById("root").classList.remove("portrait");
    document.getElementById("root").classList.add("landscape");
  }
}
playThisMovie = (data, props) => {
  var tl = anime.timeline();

    tl.add({
        targets: '#toHome, #playMovie',
        translateX: [0, -200],
        opacity: [1, 0],
        easing: 'easeOutQuad',
        duration: 100,
        loop: false
    })
    .add({
            targets: '.landinghero .posterImg',
            translateX: [0, -200],
            opacity: [1, 0],
            easing: 'easeOutQuad',
            duration: 200,
            loop: false
        })
    .add({
      targets: '.landinghero .heading, .headerMain, .landinghero .dv-star-rating, .landinghero div[role=button]',
            translateY: [0, -200],
            opacity: [1, 0],
            easing: 'easeOutQuad',
            duration: 200,
            loop: false
        })
    .add({
        targets: '.landinghero .subheader, .landinghero .genreHolder',
        translateY: [0, 200],
        opacity: [1, 0],
        delay: 50,
        easing: 'easeOutQuad',
        loop: false
    })
    .add({
        targets: '#iFrameFullScreen',
        // scale: [0, 1],
        opacity: [0, 1],
        easing: 'easeOutQuad',
        loop: false,
        begin: function () {
            document.getElementById('iFrameFullScreen').classList.add("fullscreen");
        }
    })
    .add({
        targets: '.closeIframe',
        translateX: [-200, 0],
        opacity: [0, 1],
        easing: 'easeOutQuad',
        loop: false,
        complete: function () {
            document.getElementById('closeIframe').classList.add("active")
        }
    })
    }
  state = this.state;

  componentDidMount() {
    window.scrollTo(0, 0)
    var params = {
      api_key: "4d9c9de3bdf0d3b6837c49c086e3b190",
      append_to_response: 'videos,images,reviews,credits'
    };
    var url =
      "https://api.themoviedb.org/3/movie/" +
      this.state.movieID +
      "?" +
      Helper.getAsUriParameters(params);
    axios.get(url).then(res => {
      this.setState({
        item: res.data
      });

      var streamsParam = {
        imdb_id: res.data.imdb_id,
        api_key: "bc207916f05ce5d24d97e749cee9d0eb",
        video: true
      };
      var streamUrl ="https://api.odb.to/embed?" + Helper.getAsUriParameters(streamsParam);

      axios.get(streamUrl).then(res => {
            var el = document.createElement('html');
            el.innerHTML = res.data;
            var htmlObject = el;
            if(htmlObject.getElementsByTagName("iframe").length){
                this.setState({
                    streamUrl: htmlObject.getElementsByTagName("iframe")[0].src
                });
            }
        });

        this.setState({
            iframeBody: res,
            isLoaded: true
        });
    });

    this.handleChange();
    
  }
  render() {
      const { error, isLoaded, item, streamUrl } = this.state;
      const opts = {
        height: '195',
        width: '320',
        modestbranding: 1,
        playerVars: { // https://developers.google.com/youtube/player_parameters
          autoplay: 0,
          controls: 0,
          rel: 0,
          showinfo: 0
        }
      };
      if (error) {
          return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <Loader />;
    } else {

      var thisMovie = item;
      var PlayButton;
      if (streamUrl !== undefined) {
        PlayButton = <Button id="playMovie" onClick={(e) => this.playThisMovie(thisMovie, this, e)} className="herobutton"><PlayArrow /> Play</Button>
      } else {
        PlayButton = '';
      }
      
      var thisGenres = '';
      thisGenres = thisMovie.genres.map((genre) => {
        return (
          <span className="genreItem" key={Math.random()}>{genre.name}</span>
        )
      })

        var thisCast = '';
        thisCast = thisMovie.credits.cast.map((cast) => {
          if(cast.character !== ''){
            return (
              <span className="crewItem" key={Math.random()}><span className="name_">{cast.name}</span> as <span className="role_">{cast.character}</span></span>
              )
            }else{
              return (<span></span>)
            }
          })
          
        // var thisTrailers = '';
        var thisTrailersBG = '';
        var trailerYoutube = '';
        // thisTrailers = (thisMovie.videos.results.map((trailer) => {
          //     return (
          //         <YouTube
          //           videoId={trailer.key}
          //           opts={opts}
          //           onReady={this._onReady}
          //         />
          //       )
          //     }))
        thisTrailersBG = thisMovie.videos.results[0];
        
        if (thisTrailersBG !== undefined){
          trailerYoutube = <YouTube
                                  videoId={(thisTrailersBG !== undefined) ? thisTrailersBG.key : "jssO8-5qmag"}
                                  opts={opts}
                                  onReady={this._onReady}
                                />
        }else{
          trailerYoutube = <center className="notavailable">Not available</center>
        }
        // alert(thisTrailers.key);

        var withYT = (
            <YoutubeBackground
              videoId={(thisTrailersBG !== undefined) ? thisTrailersBG.key : null} // default -> "jssO8-5qmag"
              aspectRatio="16:9" // default -> "16:9"
              overlay="rgba(0,0,0,.2)" // defaults -> null | e.g. "rgba(0,0,0,.4)"
              className="trailerElement" // defaults -> null
              onReady={(e) => { this.fadeInBackgroundVid() }} // defaults -> null
              muted={true}
              onError={(e) => { console.log('wassap') }}
            >
              <LazyHero
                imageSrc={null}
                isFixed={true}
                isCentered={true}
                transitionTimingFunction="ease-in-out"
                transitionDuration={900}
                parallaxOffset={100}
                className="landinghero"
                minHeight="85vh"
              >
                <div className="mainFilm">
                  <Link id="toHome" className="herobutton" to="/">
                    <Icon icon={ic_navigate_before} size={32} />
                  </Link>
                  {PlayButton}
                  <div className="container ">
                    <Grid container className="maxWidth" spacing={16}>

                      <Grid key={Math.random()} item xs={12} sm={3} md={3}>
                        <img className="posterImg" src={`https://image.tmdb.org/t/p/w300/${thisMovie.poster_path}`} alt=""/>
                      </Grid>

                      <Grid key={Math.random()} item xs={12} sm={9} md={9}>
                        <div className="row padding-reset-x-0">

                          <StarRatingComponent
                            name="rate2"
                            editing={false}
                            renderStarIcon={() => <span><Icon icon={happy} size={35} /></span>}
                            starCount={5}
                            value={Math.floor(thisMovie.vote_average / 2)}
                          />
                        </div>
                        <h1 className="heading">
                          {thisMovie.title}

                          <small>{thisMovie.tagline}</small>
                        </h1>

                        <Chip
                          label={item.release_date.split("-")[0]}
                          color="primary"
                        />


                        <div className="row padding-reset-x-0">

                          <h4 className="subheader">{thisMovie.overview}</h4>
                          <div className="genreHolder">{thisGenres}</div>
                        </div>
                      </Grid>
                    </Grid>

                  </div>

                </div>
              </LazyHero>
        </YoutubeBackground> 
        )

        var withoutYT = (
          <LazyHero
            imageSrc={`https://image.tmdb.org/t/p/original/${thisMovie.backdrop_path}`}
            isFixed={true}
            isCentered={true}
            transitionTimingFunction="ease-in-out"
            transitionDuration={300}
            parallaxOffset={150}
            className="landinghero"
            minHeight="85vh"
          >
            <div className="mainFilm">
              <Link id="toHome" className="herobutton" to="/">
                <Icon icon={ic_navigate_before} size={32} />
              </Link>
              {PlayButton}
              <div className="container ">
                <Grid container className="maxWidth" spacing={16}>

                  <Grid key={Math.random()} item xs={12} sm={3} md={3}>
                    <img className="posterImg" src={`https://image.tmdb.org/t/p/w300/${thisMovie.poster_path}`} />
                  </Grid>

                  <Grid key={Math.random()} item xs={12} sm={9} md={9}>
                    <div className="row padding-reset-x-0">

                      <StarRatingComponent
                        name="rate2"
                        editing={false}
                        renderStarIcon={() => <span><Icon icon={happy} size={35} /></span>}
                        starCount={5}
                        value={Math.floor(thisMovie.vote_average / 2)}
                      />
                    </div>
                    <h1 className="heading">
                      {thisMovie.title}

                      <small>{thisMovie.tagline}</small>
                    </h1>

                    <Chip
                      label={item.release_date.split("-")[0]}
                      color="primary"
                    />


                    <div className="row padding-reset-x-0">

                      <h4 className="subheader">{thisMovie.overview}</h4>
                      <div className="genreHolder">{thisGenres}</div>
                    </div>
                  </Grid>
                </Grid>

              </div>

            </div>
          </LazyHero>
        )

      this.fadeInDetails();
      return (
        <div className="mainPageMovie"> 
          <div className="heroBg" style={{ backgroundImage: `url("` + `https://image.tmdb.org/t/p/original/` + thisMovie.backdrop_path + `")` }}> 
          
            <UserAgent render={({ ua }) => {
              if(thisTrailersBG === undefined){
                return withoutYT;
              }else{
                return ua.mobile ? withoutYT : withYT  ;
              }
            }} />

          
          </div>
          <div className="container">
            <h2 className="maxWidth sectionHeader">
              <Icon
                icon={ic_slideshow}
                size={32}
                className="primarytext"
              />{" "}
              Trailer
            </h2>
            <div className="videoHolder maxWidth marginAuto">
              {trailerYoutube}
            </div>
          </div>

          <div className="container">
            <h2 className="maxWidth sectionHeader">
              <Icon
                icon={ic_camera_front}
                size={32}
                className="primarytext"
              />{" "}
              Film Cast
            </h2>
            <div className="castHolder maxWidth marginAuto">
              <ScrollMenu
                data={thisCast}
              />
            </div>
          </div>
          <Iframe height="100%" width="100%" src={this.state.streamUrl} id={this.state.movieID}/>
          <Recommended />
        </div>
      );
    }
  }
}


export default reloadable(withRouter(Movie));
