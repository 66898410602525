const helpers = {
    getAsUriParameters: function (data) {
        var url = '';
        for (var prop in data) {
            if (data[prop]) {
                url += encodeURIComponent(prop) + '=' +
                    encodeURIComponent(data[prop]) + '&';
            }
        }
        return url.substring(0, url.length - 1)
    },
}

export default helpers;