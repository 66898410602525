import React, { Component } from 'react'
import Helper from "../../helpers/General";
import axios from "axios"
import { NavLink, withRouter } from "react-router-dom";
import AliceCarousel from "react-alice-carousel";
import Loader from "./Loader";
// import Grid from "@material-ui/core/Grid";
// import Card from "@material-ui/core/Card";
import { withStyles } from "@material-ui/core/styles";
import "react-alice-carousel/lib/alice-carousel.css";
import red from "@material-ui/core/colors/red";
import Chip from "@material-ui/core/Chip";
import { Icon } from "react-icons-kit";
import { ic_live_tv } from 'react-icons-kit/md/ic_live_tv'
const styles = theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        overflow: "hidden",
        backgroundColor: "black"
    },
    grid: {
        background: "black"
    },
    maxwidth: {
        maxWidth: "1500px"
    },
    gridholder: {
        background: "black",
        padding: "2em"
    },
    card: {
        margin: "auto",
        backgroundColor: "#252424",
        backgroundSize: 'cover',
        backgroundPosition: 'center'
        // height: '100%'
    },
    cardbgtransparent: {
        background: "#252525d9",
        color: "white !important"
    },
    media: {
        height: 0,
        paddingTop: "56.25%" // 16:9
    },
    actions: {
        display: "flex"
    },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: "rotate(180deg)"
    },
    avatar: {
        backgroundColor: red[500]
    }
});

class Recommended extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item: [],
            isLoaded: false,
            count: 0,
            error: false
        };
    }
    state = this.state;
    responsive = {
        0: { items: 2 },
        600: { items: 2 },
        1024: { items: 4 },
        1300: { items: 5 },
    };
    onSlideChange(e) {
        // console.log('Item`s position during a change: ', e.item);
        // console.log('Slide`s position during a change: ', e.slide);
    };
    
    onSlideChanged(e) {
        // console.log('Item`s position after changes: ', e.item);
        // console.log('Slide`s position after changes: ', e.slide);
    };
    
    galleryItems = (itemObj) => {
        const handleOnDragStart = e => e.preventDefault();
        return (
            itemObj.map((item) => (
                <NavLink id={"item-" + item.id} to={"/item/"+item.id} key={`key-` + item.id}>                
                <div key={`key-${item.title}`} onDragStart={handleOnDragStart}  className="recommendedItem" style={{ backgroundImage: `url("` + `https://image.tmdb.org/t/p/w300/` + item.backdrop_path + `")` }}>
                        <div className="detailsholder">
                            <div className="textholder">
                                {/* <p>{item.overview}</p> */}
                                <h2>{item.title}</h2>
                                <Chip label={item.release_date.split("-")[0]} color="primary"/>
                            </div>
                        </div>
                </div>
                    </NavLink>
            ))
            )
    };
    
    
    
    componentDidMount() {
        var params = {
            api_key: "4d9c9de3bdf0d3b6837c49c086e3b190"
        };
        var url =
          "https://api.themoviedb.org/3/movie/" +
            this.props.match.params.id +
          "/similar?" +
          Helper.getAsUriParameters(params);

        axios.get(url).then(res => {
            this.setState({
                items: res.data,
                isLoaded: true
            });
        });
    }

    render() {
        const { error, isLoaded, items } = this.state;
        const { classes } = this.props;
        
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <Loader />;
        } else {

            const items_ = this.galleryItems(this.state.items.results);
            return (
            <div>
                <h2 className="maxWidth sectionHeader"><Icon icon={ic_live_tv} size={32} className="primarytext"/> You might also like</h2>
                <AliceCarousel
                items={items_}
                duration={2400}
                autoPlay={true}
                startIndex={1}
                fadeOutAnimation={true}
                mouseDragEnabled={true}
                autoPlayInterval={6000}
                dotsDisabled={true}
                buttonsDisabled={true}
                autoPlayDirection="ltr"
                responsive={this.responsive}
                disableAutoPlayOnAction={true}
                onSlideChange={this.onSlideChange}
                onSlideChanged={this.onSlideChanged}
            /></div>
            )
        }


    }
};

export default withRouter(withStyles(styles)(Recommended))
