import React, { Component } from 'react'
// import axios from 'axios'
// import Loader from '../components/inc/Loader'
import HeroSection from '../components/inc/LandingHero'
import ListSection from '../components/inc/MovieList'
import SearchSection from '../components/inc/SearchList'

class Home extends Component {
    state = {
        items: []
    }
    componentDidMount(){

    }
    render() {
        return (
          <div className="container text-center">
            <HeroSection/>
            <SearchSection/>
            <ListSection/>
          </div>
        )
    }
}

export default Home