import React, { Component } from 'react'
// import Navbar from './components/Navbar'
import { BrowserRouter, Route } from 'react-router-dom'

import Home from './components/Home'
import About from './components/About'
import Contact from './components/Contact'
import Movie from './components/Movie'
import MovieList from './components/inc/MovieList'
// import { TransitionGroup, CSSTransition } from "react-transition-group";
class App extends Component {
  componentWillMount() {
    document.title = "Watch8"
  }
  componentWillUnmount() {
    document.title = "Watch8"
  }
  render() {
    return (
      <BrowserRouter basename="/noodph">
        <div className="App">
          {/* <Navbar /> */}
          
          <Route path="/" exact component={Home} />
          <Route path="/:page" exact component={Home} />
          <Route path="/item/:id" component={Movie} />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
