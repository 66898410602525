import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { NavLink, withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import $ from 'jquery';
import axios from 'axios'
import Helper from "../../helpers/General"

import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import { Icon } from "react-icons-kit";
import { ic_arrow_back } from 'react-icons-kit/md/ic_arrow_back'
import { ic_arrow_forward } from 'react-icons-kit/md/ic_arrow_forward'
import { ic_remove } from 'react-icons-kit/md/ic_remove'
import { ic_star } from 'react-icons-kit/md/ic_star'
import { happy } from 'react-icons-kit/icomoon/happy'
import { film } from 'react-icons-kit/icomoon/film'
import StarRatingComponent from './StarRatingComponent';
window.React = React;

export class CommentList extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
  };

  render() {

    let movieNodes = this.props.data.map(function(movie, index) {
      return (
        <Grid key={movie.id} item xs={6} sm={4} md={3}>
      <NavLink to={`/item/${movie.id}`} key={index}>

            {/* <div className="movieOverlay">&nbsp;</div> */}
            <div className="movieCard" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, .5) 20%, rgba(0, 0, 0, 0.75) 90%, rgb(0, 0, 0) 100%), url("` + `https://image.tmdb.org/t/p/w500/` + movie.backdrop_path + `")` }}>
            <div className="movieDescription">
                <small className="primarytext">{movie.release_date.split("-")[0]}</small> 
                <h4 className="movieTitle">{movie.title}</h4>
                <div>
                  <StarRatingComponent
                    name="rate2"
                    editing={false}
                    renderStarIcon={() => <span><Icon icon={happy} size={15} /></span>}
                    starCount={5}
                    value={Math.floor(movie.vote_average / 2)}
                  />
                </div>
              <p>{movie.overview}</p>
              
            </div>
          </div>
      </NavLink>
        </Grid>
      );
    });

    return (<Grid container className="maxWidth" spacing={12}>{movieNodes}</Grid>);
  }
}

export class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      offset: 0,
    };
    this._isMounted = false;
  }

  async loadCommentsFromServer() {
    var params = {
        api_key: "4d9c9de3bdf0d3b6837c49c086e3b190",
      append_to_response: 'images,people',
      page: (this.state.page) ? Math.ceil(this.state.page+1) : 1 
    };

    var url = "https://api.themoviedb.org/3/trending/movie/day?" + Helper.getAsUriParameters(params);
    axios.get(url)
        .then(data => {
          var data_ = data.data;
          this._isMounted && this.setState({
            data: data_.results,
            pageCount: Math.ceil(data_.total_results / data_.results.length),
          });
        })
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadCommentsFromServer();
    
  }
  componentWillUnmount(){
    this._isMounted = false;
      
  }

  handlePageClick = data => {
    let selected = data.selected;
    let offset = Math.ceil(selected * 20);

    this.setState({ offset: offset, page: selected }, () => {
      this.loadCommentsFromServer();
    });
  };

  render() {

    if(this._isMounted){
      // console.log(this.state);
      return (
        <div>
          <h2 className="maxWidth sectionHeader">
            <Icon
              icon={ic_star}
              size={32}
              className="primarytext"
            />{" "}
            Trending Movies
            </h2>
          <CommentList data={this.state.data} />
         
          <ReactPaginate
            previousLabel={<Icon icon={ic_arrow_back} size={32} />}
            nextLabel={<Icon icon={ic_arrow_forward} size={32} />}
            breakLabel={<Icon icon={ic_remove} size={32} />}
            breakClassName={'break-me'}
            pageCount={this.state.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={this.handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
          />
        </div>
      );
    }else{
      return (<div>Loading...</div>)
    }

  }
}


ReactDOM.render( <App url={'https://api.themoviedb.org/3/trending/movie/day?api_key=4d9c9de3bdf0d3b6837c49c086e3b190'} author={'adele'} perPage={10} />, document.getElementById('root') );

export default withRouter(App);
